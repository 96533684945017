import React from "react"

import PageWrapper from "../components/PageWrapper"
import { t } from "@lingui/macro"
import SEO from "../components/SEO"

const Demos = ({ location }) => {
  return (
    <>
      <SEO
        pathname={location.pathname}
        title={t`Demos`}
        description={t`Demonstration von künstlicher Intelligenz. AMAI hilft Ihnen, Künstliche Intelligenz in Ihre Prozesse und Produkte zu integrieren. Auf dem Gebiet des Machine Learning, Deep Learning sind wir Experten.`}
      />
      <PageWrapper>
        <iframe
          src="https://demos.am.ai?header=false"
          allow="camera;microphone"
          width="100%"
          height="800px"
          title="AMAI Demos"
          css={{ marginTop: 100, border: 0 }}
        ></iframe>
      </PageWrapper>
    </>
  )
}

export default Demos
